/* jQuery/Chrome workaround */

jQuery.event.special.touchstart = {
  setup: function( _, ns, handle ){
    this.addEventListener("touchstart", handle, { passive: false });
  }
};
jQuery.event.special.touchmove = {
  setup: function( _, ns, handle ){
    this.addEventListener("touchmove", handle, { passive: false });
  }
};


var product = new Product({});
var customer = new Customer();
var cookieObj = new Cookie();
var shoppingCart = new ShoppingCart();
var wishlist = new WishList();

if( PROSKTR.content == "product_info" && $('#add_product-form').length ) {
	cookieObj.addViewedProduct( $('#add_product-form').data('productsId').toString() );
}

function loadOmni(element) {
	window.ChatraGroupID = 'iEmFhtAEcHLhFGEna';
	!function(e,o){!window.omni?window.omni=[]:'';window.omni.push(o);o.g_config={widget_id:"13681-x5a9njh8"}; o.email_widget=o.email_widget||{};var w=o.email_widget;w.readyQueue=[];o.config=function(e){ this.g_config.user=e};w.ready=function(e){this.readyQueue.push(e)};var r=e.getElementsByTagName("script")[0];c=e.createElement("script");c.type="text/javascript",c.async=!0;c.src="https://omnidesk.ru/bundles/acmesite/js/cwidget0.2.min.js";r.parentNode.insertBefore(c,r)}(document,[]);
	let interv = setInterval(function() {
		if ( typeof OmniWidgetApi != 'undefined' && $('[id^=omni_widget_iframe]').length ) {
			$('[id^=omni_widget_iframe]').on('load', function(){
				OmniWidgetApi.call('open');
				$(element).hide();
				clearInterval(interv);
			});
		}
	}, 300);
}

$('.j-categories-descr-show').each(function() {
	if ( $(this).prev().outerHeight() > 200 ) {
		$(this).removeClass('hidden').prev().addClass('short');
	}
	$(this).on('click', function() {
		$(this).addClass('hidden').prev().removeClass('short');
	});
});

$('.j-index-product-show-sizes').click(function(){
	var get = {ajax:3},
		action = $('#index-product-show-sizes').attr('action');

	$('#index-product-show-sizes').serializeArray().forEach(function(data){
		get[data.name] = data.value;
	});
	$('#index-product-show-sizes .error').removeClass('active');

	$.get(action,get).done(function(data){
		var html = '';

		if(typeof data.data != "object"){
			error();
			return true;
		}
		for(var block in data.data){
			html = data.data[block];
			break;
		}
		if(html) {
			$('.j-index-product-size-table output').html(html);
		}else{
			error()
		}
	}).fail(function(){
		error()
	}).always(function() {
		$('body').addClass('overlay');
		$('.j-index-product-size-table').addClass('active');
	});

	function error(){
		$('.j-index-product-size-table .error').addClass('active');
	}
});
$('.j-index-product-size-table .j-close').click(function(){
	$('body').removeClass('overlay');
	$('.j-index-product-size-table').removeClass('active');
});

$(function() {
	initSliders();
	initHeader();
	initProductCart();
	initMainPage();
	initSubscribe();
	initListing();
	initFavorites();
	initBasket();
	initLoginForm();
	initCabinet();
	initProductDelivery();
	/* -------------------------------------------------------- */
	/* popups and sidebars                                      */
	/* -------------------------------------------------------- */

	var popup_stack = [];

	var get_overlays = function (defs) {
		if (defs == '-') return $();
		if (!defs) return $('body');
		defs = defs.split('/\s*,\s*/');

		var rv = $(defs.shift());
		while (defs.length)
			rv.add($(defs.shift()));

		return rv;
	}

	var overlays_intersect = function (o1, o2) {
		if (o1 && o2) {
			o1 = o1.split(/, +/);
			o2 = o2.split(/, +/);

			for (var i = 0; i < o1.length; i++)
				if (o2.includes(o1[i]))
					return true;

		}

		return false;
	}

	// closing
	$('body').on('click', '*[data-close]', function () {

		if (popup_stack.length) {
			var entry = popup_stack.pop();
			if (entry.cloned) {
				$(entry.target).toggleClass('active');
				window.setTimeout(function () {
					$(entry.target).remove();
				}, 300);
			} else {
				$(entry.target).toggleClass('active');
				$(entry.target).trigger('close');
			}
			get_overlays(entry.overlay).toggleClass(entry.klass);
		}

		return false;
	})

	function popup_handler(
		target,
		overlay,
		overlay_class,
		source,
		replace
	) {

		// remove current dropdowns
		$('.dropdown.active').removeClass('active');

		// replace ontop active element
		if (replace) {
			var entry = popup_stack[popup_stack.length - 1];
			$(entry.target).toggleClass('active');
			$(target).toggleClass('active');

			entry.target = target;

			return false;
		}

		// normal operation
		var overlay_set = get_overlays(overlay);

		var overlay_class = (overlay_class
				? overlay_class
				: 'overlay'
		);
		overlay_class = overlay_class.split(/\s+/);

		if (popup_stack.length) {
			var top_popup = popup_stack[popup_stack.length - 1];
			if (overlays_intersect(top_popup.overlay, overlay) && top_popup.target != target) {
				top_popup.source.trigger('click');
			}
		}

		if ($(source).attr('data-clone')) {
			var old_target = $(target);
			target = old_target.clone();
			target.removeAttr('id');
			target.insertAfter(old_target);
		}

		overlay_set.toggleClass(overlay_class);

		// already active...
		if ($(target).hasClass('active')) {
			popup_stack.pop();
			$(target).toggleClass('active');
			$(target).trigger('close');
		} else {
			popup_stack.push({
				overlay: overlay,
				source: source,
				target: target,
				klass: overlay_class,
				cloned: $(source).attr('data-clone')
			});
			$(target).trigger('opened');

			window.setTimeout(function () {
				$(target).toggleClass('active');
				$(target).find('[data-src]').each(function () {
					$(this).attr('src', $(this).attr('data-src'));
				})
				if(target == ".search"){
					$('.search input').focus();
				}
			}, 10);

		}

		return false;
	}
	// open
	$('body').on('click', '*[data-target]', function () {
		popup_handler(
			$(this).attr('data-target'),
			$(this).attr('data-overlay') ? $(this).attr('data-overlay') : 'body',
			$(this).attr('data-overlay-class') ? $(this).attr('data-overlay-class') : null,
			$(this),
			$(this).attr('data-replace') ? true : false
		);
		return false;
	})

	$(document).on('click', '.overlay', function (e) {

		if (popup_stack.length) {
			// clear all
			if (e.target.tagName == 'BODY') {
				popup_stack.reverse().forEach(function (entry) {
					get_overlays(entry.overlay).toggleClass(entry.klass);
					$(entry.target).toggleClass('active');
					$(entry.target).trigger('close');
				})
				popup_stack = [];
			} else {
				var entry = popup_stack[popup_stack.length - 1];
				var overlays = get_overlays(entry.overlay);

				overlays.each(function () {
					if (this == e.target) {
						overlays.toggleClass(entry.klass);
						$(entry.target).toggleClass('active');
						popup_stack.pop();
						$(entry.target).trigger('close');
						return false;
					}

				})

			}
		}

	})

	/* -------------------------------------------------------- */
	/* category filter                                          */
	/* -------------------------------------------------------- */
	$('body').on('click', '.collapsed > a:not([href])', function () {
		$(this).parent().removeClass('collapsed');
		return false;
	})

	/* -------------------------------------------------------- */
	/* Parallax                                                 */
	/* -------------------------------------------------------- */
	if ($('[data-parallax]').length) {
		var parallaxed = $('[data-parallax]');

		$(window).on('scroll', function (e) {

			var diff = (window.scrollY / window.innerHeight);

			parallaxed.each(function () {
				var k = Number($(this).attr('data-parallax'));
				var offset = diff * k;

				$(this).css({transform: `translateY(${offset * 100}%)`});

			})
		})
	}

	/* -------------------------------------------------------- */
	/* Dropdowns                                                */
	/* -------------------------------------------------------- */
	$(document).on('click', '[data-dropdown-overlay]', function () {
		var target = $(this).next();
		var self = $(this);

		// turn of existing
		var existing = $('[data-dropdown-overlay] + .active');
		if (existing.length) {
			existing.removeClass('active');
			existing.prev().closest(self.attr('data-dropdown-overlay')).removeClass('overlay')
			$(document).off('click.DROP_DOWN')
		}

		// mobile|tablet behaviour
		if (target.css('position') == 'fixed') {
			$('body').addClass('overlay');
			self.closest($(this).attr('data-dropdown-overlay')).addClass('overlay')
		}

		// adding the class
		target.addClass('active');

		// adding turn-off click
		$(document).on('click.DROP_DOWN', function (e) {

			if ($(e.target).closest('.popup').length)
				return;

			if (!$(e.target).closest(target).length) {
				target.removeClass('active');
				$(document).off('click.DROP_DOWN')

				$('body').removeClass('overlay');
				self.closest(self.attr('data-dropdown-overlay')).removeClass('overlay')
			}

		})

	})

	// closing dropdown with a `X` 
	$(document).on('click', '.dropdown .close', function () {
		$(document).trigger('click.DROP_DOWN');
	})

	/* -------------------------------------------------------- */
	/* Accordeon                                                */
	/* -------------------------------------------------------- */
	$('.accordeon').each(function () {

		var self = $(this);
		$(this).children().children('a').on('click', function () {
			if ($(this).hasClass('expanded')) {
				$(this).removeClass('expanded')
			} else {
				self.find('.expanded').removeClass('expanded');
				$(this).toggleClass('expanded');
			}

			return false;
		})

	})

	/* -------------------------------------------------------- */
	/* Main menu mobile expand                                  */
	/* -------------------------------------------------------- */
	$('.main_menu').on('click', 'a', function () {

		if ($(this).next().prop('tagName') != 'NAV') return true;
		if ($(this).parent().hasClass('main_menu')) return true;

		var mm = $(this).closest('.main_menu');
		if (mm.css('position') != 'fixed') return true;

		$(this).toggleClass('expanded');

		var target = $(this).next();

		if ($(this).hasClass('expanded')) {
			$(this).parent().siblings().children('a.expanded').trigger('click');

			var oh = getHeightSubmenu(target);

			target.attr('data-max-height', oh);
			target.css('maxHeight', oh);

			$(this).parents('[data-max-height]').each(function () {
				var nmh = getHeightSubmenu($(this));

				$(this).attr('data-max-height', nmh);
				$(this).css('maxHeight', nmh);
			})

		} else {
			target.css('maxHeight', 0);
			var oh = Number(target.attr('data-max-height'));
			$(this).parents('[data-max-height]').each(function () {
				var nmh = Number($(this).attr('data-max-height')) - oh
				$(this).attr('data-max-height', nmh);
				$(this).css('maxHeight', nmh);
			})

		}

		return false;

	})

	/**
	 * special for IOS
	 * @param navElement
	 */
	function getHeightSubmenu(navElement){
		if(!navElement.length){
			return 0;
		}
		var height = 0,
			defaultItemHeight = 48,
			childrenCount = navElement.children().length,
			hasOpenSubMenu = $('.expanded',navElement).length > 0 ;

		height = defaultItemHeight * childrenCount;
		if(hasOpenSubMenu){
			$('.expanded',navElement).each(function(){
				height += getHeightSubmenu($(this).next());
			});
		}
		return height;
	}

	/* -------------------------------------------------------- */
	/* Notification close                                       */
	/* -------------------------------------------------------- */
	$(document).on('click', '.notification .close', function () {
		$(this).closest('.notification').removeClass('active');
		return false;
	})

	/* -------------------------------------------------------- */
	/* Carousels                                                */
	/* -------------------------------------------------------- */

	function initSliders() {
		initOldSliders();
		initSlickSliders();

		function initOldSliders() {
			var fw_carousel = null;
			$('.carousel').each(initSlider);

			window._init_slider = initSlider;

			function initSlider() {
				var noSliders = !$(this).find('.viewport').children().length
				if (noSliders) {
					return false
				}

				var carousel = null;
				var carousel_container = $(this);
				var params = {
					dots: false,
					looped: false,
					align: 'start',
					arrows_scroll_by: 4,
					interactive_clicks: false,
					animation_duration: 800,
					animation_interrupt_action: 'stop',
					arrows_template: `
<div class='finalCarouselControlsArrows'>
	<a class='finalCarouselControlArrowLeft'></a>
	<a class='finalCarouselControlArrowRight'></a>
</div>
			`,
					on: {
						init_after: [function () {
							carousel = this;
						}],
						scroll_start_before: [],
						interactive_scroll_start: [function (e, delta, scroll_state) {
							if (e.type == 'touchmove') {
								if (Math.abs(delta.x) > Math.abs(delta.y)) {
									e.preventDefault()
								} else {
									this.detachCurrentPointerEvents(scroll_state);
								}
							}
						}]
					}
				};

				// product selector
				if ($(this).parent().hasClass('product_selector')) {
					params.dots = true;
					params.looped = true;
					params.active_class = 'active';

					var markers = $(this).siblings().first().find('span');
					markers.on('click', function () {
						carousel.scrollTo($(this).prevAll('span').length);

						var p = carousel.__container.parent();
						if (
							carousel.__container.parent().css('position') == 'fixed'
							&& !p.hasClass('active')
						) {
							$(this).closest('section').nextAll('.button').click();
						}
					})

					var opened = false;
					$(this).parent().on('close', function () {
						markers.first().parent().css('transform', '');
						var section = $(this).children('section');
						section.css('transform', '');
						opened = false;
					})

					var scale_fix = 1;
					$(this).parent().on('opened', function () {
						var section = $(this).children('section');

						var state = {
							h: section.height(),
							t: section.offset().top - window.scrollY,
							w: section.width(),
						}
						var scale = window.innerWidth / state.w;
						scale_fix = 1 / scale;
						var h_fix = (state.h * scale - state.h) / 2;

						section.css('transform', `scale(${scale}) translateY(${state.t * -1 + h_fix * -1}px)`);
						opened = true;
					})

					params.on.scroll_start_before = function (state) {

						var target_marker = markers.eq(state.ending_index);
						target_marker.addClass('active').siblings().removeClass('active');

						state.starting_slide.addClass('fadeout');

						var p = carousel.__container.parent();
						var ph = (
							(p.css('position') == 'fixed') //&& p.hasClass('active')
								? p.outerHeight()
								: 0
						);

						if (ph && opened) {
							var diff = window.innerHeight - ph;
							var desired_offset = diff / 2;
							var ty = desired_offset - target_marker.position().top;
							target_marker.parent().css('transform', `translateY(${ty * scale_fix}px)`);

						}

					};
					params.on.scroll_end_after = function () {
						this.__container.children('.fadeout').removeClass('fadeout');
					}

				}

				// medium
				if ($(this).hasClass('medium')) {
					// will not init carousel
					//  for larger dimensions
					if (window.innerWidth > 640) {
						return;
					}

					params.arrows = false;
					params.looped = true;
					params.dots = true;

				}

				// product list
				if ($(this).hasClass('products_list')) {
					params.active_class = 'active';
					params.arrows_scroll_by = 1;
					params.always_scroll = true;

					if (window.innerWidth <= 1008)
						params.align = 'center';

					$(window).on('resize', function () {
						var changed = false;

						if (window.innerWidth > 1008) {
							if (params.align != 'start') {
								params.align = 'start';
								changed = true;
							}

						} else if (params.align != 'center') {
							params.align = 'center';
							changed = true;
						}

						if (changed) {
							carousel.init(false);
							carousel.scroll(0);
						}

					})
					if ($(this).find('.viewport').children().length == 1) {
						$(this).find('.product_small').css({
							'flex': '0 0 50%',
							'padding-left': '15px',
							'padding-right': '15px'
						});
					}
				}

				// full screen
				if ($(this).hasClass('full_screen')) {

					$(this).children('.viewport').css({
						"--full_screen_width": `${window.screen.width}px`
					})

					params.arrows_scroll_by = 1;
					params.interactive_scroll = false;
					params.arrows_template = `
				<div class='finalCarouselControlsArrows'>
					<a class='finalCarouselControlArrowLeft'></a>
					<a class='close icon icon-close' data-close="true"></a>
					<a class='finalCarouselControlArrowRight'></a>
				</div>
			`;

					params.on.init_after.push(function () {
						fw_carousel = this;
					});

				}

				// huge index carousel
				if ($(this).hasClass('huge')) {
					params.arrows = false;
					params.dots = true;
					params.looped = true;

					params.active_class = 'active',
						params.animation_duration = 1000,

						// interactive_scroll: false,
						// params.interactive_clicks = 1;
						// params.interactive_clicks_exclude = ['a','button'];

						params.interactive_scroll = 1;
					params.interactive_scroll_coords_mod = -1;
					params.always_scroll = true;

					params.animation_interrupt_action = 'finish';
					params.on.scroll_start_before.push(function (state) {
						state.starting_slide.addClass('fadeout');
					})
					params.on.scroll_end_after = function () {
						this.__container.children('.fadeout').removeClass('fadeout');
					};

				}

				// large product carousel
				if ($(this).hasClass('large')) {
					params.arrows_scroll_by = 1;
					params.dots = true;

					params.on.scroll_start_before.push(function () {
						var thumbs = carousel_container.children('.thumbs').children();

						thumbs.removeClass('active');
						thumbs.eq(this.state.current).addClass('active');
					});

					if (!$(this).hasClass('popup')) {
						$(this).on('click', 'a.icon-plus', function () {
							fw_carousel.scrollTo(
								carousel.state.current,
								{
									animation_duration: 0
								}
							);
							popup_handler(
								'.popup.full_screen.carousel'
							);
						})
						$(this).on('click', '.viewport a:not([class])', function () {

							fw_carousel.scrollTo(
								$(this).parent().prevAll(':not(.finalCarouselClone)').length,
								{
									animation_duration: 0
								}
							);

							popup_handler(
								'.popup.full_screen.carousel'
							);

							return false;

						})
					}

				}
				// wishlist products carousels
				if ($(this).children('.back').length) {
					params.dots = true;
					params.interactive_scroll = false;
					params.interactive_clicks = false;
					params.arrows_scroll_by = 1;
				}

				try {
					$(this).children('.viewport').finalCarousel(params);
				} catch (err) {
					//console.error(err);
				}

				if ($(this).hasClass('large')) {
					$(this).on('click', '.thumbs > *', function () {
						carousel.scrollTo($(this).prevAll().length);
						return false;
					})
				}

			}
		}

		function initSlickSliders(){
			$('.js-slider').slick({
				infinite: false,
				slidesToShow: 4,
				slidesToScroll: 4,
				arrows:true,
				dots:false,
				rows: 0,
				responsive: [
					{
						breakpoint: 992,
						settings: {
							slidesToShow: 3,
							slidesToScroll: 3,
						}
					},
					{
						breakpoint: 600,
						settings: {
							slidesToShow: 2,
							slidesToScroll: 2,
						}
					},
					{
						breakpoint: 480,
						settings: {
							slidesToShow: 1,
							slidesToScroll: 1,
						}
					}
				]
			});
		}
	}
	$(window).resize(function () {
		$('.carousel.full_screen.active .viewport').css({
			"--full_screen_width": `${window.screen.width}px`
		})
	});

	/* -------------------------------------------------------- */
	/* PanZoom                                                  */
	/* -------------------------------------------------------- */
	$(document).imagesLoaded(function () {
		$('.popup.full_screen.carousel > .viewport > * > * ').finalPanZoom({
			zoom: {
				min: 1,
				max: 2.46
			},
			zoom_in: null,
			zoom_out: null,
			zoom_swap: 'click',
			zoom_class: 'zoom',
			inertia_deceleration: false
		});
	})

	/* -------------------------------------------------------- */
	/* Tabs                                                     */
	/* -------------------------------------------------------- */

	$(document).on('click', '.tabs > .anchors > *', function () {
		var self = $(this);
		var n = self.prevAll().length;
		self.siblings().removeClass('active');
		self.addClass('active');

		var content = self.closest('.tabs').children('.content').children();
		content.removeClass('active');
		content.eq(n).addClass('active');

		var tabs_more_info = self.closest('.tabs').children('.tabs-more-info').children();
		if (tabs_more_info) {
			tabs_more_info.removeClass('active').eq(n).addClass('active');
		}
		return false;

	})

	/* -------------------------------------------------------- */
	/* Swapping                                                 */
	/* -------------------------------------------------------- */
	$(document).on('click', '[data-hide]', function () {
		var klass = $(this).attr('data-class');
		var t = $($(this).attr('href'));
		if (t.length == 0) {
			console.warn(`Cannot find ${$(this).attr('href')} element to swap classes`);
			return false;
		}

		t.removeClass(klass);
		$(this).closest($(this).attr('data-hide')).addClass(klass);

		return false;
	})

	/* -------------------------------------------------------- */
	/* Wishlist swapping                                        */
	/* -------------------------------------------------------- */
	$(document).on('click', '.wishlist .products_list .product_wishlist', function () {

		var list = $(this).closest('.products_list');
		list.addClass('hidden');
		list.next().removeClass('hidden');

		return false;

	})

	/* -------------------------------------------------------- */
	/* Tiled block animation                                    */
	/* -------------------------------------------------------- */
	if ($('.tiled').length) {

		$(document).imagesLoaded(function () {

			var map = {};
			$('.tiled').each(function () {
				$(this).children().each(function () {
					var pos = $(this).offset().top; // + $(this).outerHeight() / 8;
					if (!map[pos]) map[pos] = [];
					map[pos].push($(this));
				})
			})
			$(window).on('scroll', function (e) {
				var scroll_pos = window.pageYOffset + document.documentElement.clientHeight;

				for (var pos in map) {
					if (Number(pos) <= scroll_pos) {
						map[pos].forEach(function (e) {
							e.addClass('inscrolled');
						})

						delete (map[pos]);
					}
				}
			})
		})
	}

	/* -------------------------------------------------------- */
	/* Share button                                             */
	/* -------------------------------------------------------- */
	$(document).on('click', '.icon-share-light', function () {
		$(this).toggleClass('active');
		$(this).next('nav').toggleClass('active');
		return false;
	})

	$(document).on('click', '.j-add-product', function(e) {
		e.preventDefault();
		var formElement = 'form.j-add-product-form[data-products-id=' + $(this).data('productsId') + ']',
				form = $(formElement, $(this).closest('form').parent());

		if( $('input[name="id[1]"]:radio', form).length && $('input[name="id[1]"]:checked', form).length == 0 ) {
			alert('�������� ��������� ������');
		} else {
			$('.j-add-product',form).toggleClass('active');
			$('.j-go-shopping-cart',form).toggleClass('active');
			shoppingCart.addProducts({
				product_info: form.serializeArray()
			}, {
				checkDublicate: false
			});
			var value = '';
			if ( $('input[name="id[1]"]:checked', form).length ) {
				value = $('input[name="id[1]"]:checked', form).val();
			} else {
				value = $('input[name="id[1]"]', form).val();
			}
			/*
			$(this).addClass('d-none')
				.parent()
				.find('.go-shopping-cart')
				.removeClass('d-none');
			 */
		}
	});

	$('.phone-11-dig').phoneMask();
	$('.default-ajax-form').ajaxForm(window.default_ajax_form_options);

	function initHeader(){
		setExtraTopTimer();

		$('.j-chose-country').click(function(){
			$.get('/templates/2xu/twig/boxes/popups/countries_links.twig',function(html){
				$('.country_select.popup nav').html(html);
			})
		});
		$('.js-top-popup-slider').slick({
			vertical:true,
			verticalSwiping:true,
			autoplay:true,
			autoplaySpeed:5000,
			dots:false,
			arrows:false,
		});

		/**
		 * ������ ��������� �
		 */
		function setExtraTopTimer() {
			if ( $('.js-top_popup-timer').length == 0 ) return;
			$('.js-top_popup-timer').each(function(){
				let $holderElement = $(this);
				let endDateStr = $holderElement.data('end_time');

				let timer = setInterval(function () {
					let now = new Date();
					let date = new Date(endDateStr);
					let msLeft = date - now;
					if (msLeft <= 0) {
						clearInterval(timer);
						$holderElement.hide();
					} else {
						let res = new Date(msLeft);
						let strTimer = '';
						if ( res.getUTCDate() > 1 ) {
							strTimer = res.getUTCDate() - 1 + ' ' + getDays(res.getUTCDate() - 1) + ' ';
						}
						strTimer = strTimer + padNumber(res.getUTCHours(), 2) + ':' + padNumber(res.getUTCMinutes(), 2) + ':' + padNumber(res.getUTCSeconds(), 2);
						$holderElement.html( strTimer );
						$holderElement.show();
					}
				}, 1000);
			})
			function padNumber(n, width, z) {
				z = z || '0';
				n = n + '';
				return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
			}

			function getDays( cnt ) {
				if ( cnt == 1 ) {
					return '����';
				} else if ( cnt < 5 ) {
					return '���';
				} else {
					return '����';
				}
			}
		}
	}

	/**
	 * �������� ������ �������
	 * @returns {boolean}
	 */
	function initListing(){
		var filtrFormEl = document.getElementById('filtr-form'),
				filtrForm = $('form#filtr-form');
		if(!$(filtrForm).length){
			return false;
		}

		window.select_form_start_position = $('.products_list ').offset().top;
		window.listing = new Listing();
		window.listing.parseOptions();
		hideInactiveClearButtons();
		listingViewSize();

		var filterForm = $('#filtr-form');
		var filterFormHolder = $('>.filter-container', filterForm);

		$('input:checkbox', filterForm).on('change', function(e) {
			$('#filtr-form .close').trigger('click');
			listing.setMultiselectHiddenValue( this );
		});

		$(document).on('click', '.j-listing-sort-block li', function(e){
			e.preventDefault();
			if( !$(this).hasClass('selected') ) {
				$(this).addClass('selected').siblings().removeClass('selected');
				$('.filter-item__header', $(this).closest('.j-listing-sort-block')).text( $(this).text() );
				$('input[name=fsort]', filterForm).val( $(this).data('value') ).trigger('change');
			}
		});

		filterForm.on('change', function(e) {
			if( !$(e.target).hasClass('j-select-size-type') ) {
				$('input[name=page]', filterForm).val( 1 );
				$(this).submit();
			}
			hideInactiveClearButtons();
		});

		$('.loadmore a').on('click', function(e) {
			e.preventDefault();
			$('#show_more').val( 1 );
			$('input[name=page]', filterForm).val( parseInt($('input[name=page]', filterForm).val()) + 1 );
			$(this).addClass('loading');
			filterForm.submit();
		});

		filterForm.on('click', '.filter-box.active .filter-title', function(e) {
			listing.clearFiltr( this );
		});
		listing.hideFiltrs();
		$('.hidden-filtr-toggler').click(function( e ) {
			e.preventDefault();
			listing.toggleHiddenFiltrs();
		});

		$('.j-reset-filter-form').on('click', function(e) { // �������� ��� �������
			e.preventDefault();
			listing.clearForm();
		} );

		// catalog filter mobile
		$('.productlist-filter-row__item__label').on('click', function(event) {
			event.preventDefault();
			/* Act on the event */
			$(this).toggleClass('drop-active');
			$(this).parent().find('.productlist-filter-row__item__wrap').toggleClass('active');
		});

		$(document).on('click', '.filter-item .js-clear-button' ,function(e){
			e.preventDefault();
			listing.clearFiltr( this );
			hideInactiveClearButtons();
		});

		var count = $('#filtr-form input[type=checkbox]:checked').length;
		$('#filtrs-count-active').text('(' + count + ')').attr('data-count',count);

		$(document).on('click', '.j-sort-list a', function(e){
			e.preventDefault();
			if( !$(this).hasClass('selected') ) {
				$(this).addClass('selected').siblings().removeClass('selected');
				//$('.j-sort-list-table', $(this).closest('.j-sorting-block')).text( $(this).text() );
				$('input[name=fsort]', filterForm).val( $(this).data('value') ).trigger('change');
			}
		});

		function hideInactiveClearButtons(){
			$('.filter-item',filterForm).each(function(){
				var countCheckedField = $('[type="checkbox"]:checked',$(this)).length;
				if(countCheckedField){
					$('.js-clear-button',$(this)).removeClass('none');
				}else{
					$('.js-clear-button',$(this)).addClass('none');
				}
			});
		}

		/**
		 * ������������� ��� ����������� �������� ������� ��� ���������
		 */
		function listingViewSize() {
			var storageName = 'size_of_listing_card',
				bigSize = 'big',
				mediumSize = 'medium',
				smallSize = 'small',
				defaultSize = mediumSize,
				allSizes = [bigSize, mediumSize, smallSize],
				buttons = {};

			buttons[bigSize] = $('[for="category_layout_max"]');
			buttons[mediumSize] = $('[for="category_layout_greedy"]');
			buttons[smallSize] = $('[for="category_layout_humble"]');
			init();

			function setSize(size) {
				localStorage.setItem(storageName, size);
				document.cookie = storageName+'='+size;
			}

			function getSize() {
				var size = localStorage.getItem(storageName),
					notValidSize = typeof size === "undefined" || allSizes.indexOf(size) === -1;

				if (notValidSize) {
					size = defaultSize;
					setSize(size);
				}
				return size;
			}

			function init() {
				setDefaultSize();
				setCurrentSize();
				setEvents();

				function setDefaultSize() {
					if(window.innerWidth>640){
						defaultSize = smallSize;
					}else{
						defaultSize = mediumSize;
					}
				}

				function setCurrentSize() {
					var currentSize = getSize();

					buttons[currentSize].trigger('click');
				}

				function setEvents() {
					for (var size in buttons) {
						buttons[size].data('size',size);
						buttons[size].click(function() {
							setSize($(this).data('size'));
						});
					}
				}

			}

		}

	}

	/**
	 * �� ��� ������� � ���������
	 */
	function initFavorites(){
		initAddToFavorites();
		initModalFavorites();

		function initModalFavorites(){
			$('.wishlist').on('opened',loadFavorites);
			$('#wish-list-clear').click(function(){
				wishlist.clear();
				$('#wishlist_productslist').html(getEmptyMessage());
				$('#favorites-carousel').html('');
				$('.j-wishlist-add').removeClass('active').data('wishlist', 0);
			});
			$('#wishlist_productslist').html(getPreloader());
		}

		function initAddToFavorites() {
			$(document).on('click', '.j-wishlist-add', function (e) {
				e.preventDefault();
				var el = this;
				if (+$(this).data('wishlist')) {
					wishlist.remove($(this).data('products_id'), function (data) {
						$(el).data('wishlist', 0).removeClass('active');
						if(typeof data.products === "undefined"){
							data.products = [];
						}
						loadFavorites(true,{productsList:data.products});
					});
				} else {
					wishlist.add($(this).data('products_id'), function (data) {
						$(el).data('wishlist', 1).addClass('active');
						loadFavorites(true,{productsList:data.products});
					});
				}
			});
		}

		function getEmptyMessage(){
			return '�����������';
		}

		function loadFavorites(reload, data){
			if(reload !==true && $('.wishlist').hasClass('j-loaded')){
				return false;
			}

			$('#wishlist_productslist').html(getPreloader());
			if(!data) {
				$.get('/wishlist?ajax=3', {}, function (data, textStatus, jqXHR) {
					sliderInit(data);
				});
			}else{
				sliderInit(data);
			}

			function sliderInit(data){
				var isEmptyList = !data.productsList.length;

				$('.wishlist').addClass('j-loaded');

				if(isEmptyList){
					$('#wishlist_productslist').html(getEmptyMessage());
					return false;
				}

				renderMainList(data);
				renderSliders(data);

				function renderMainList(data) {
					var favorites = $.render.favorites.render(data);

					$('#wishlist_productslist').html(favorites);

					initDeleteEvent();
					initShowSlideEvent();

					function initDeleteEvent() {
						$('#wishlist_productslist').on('click', '.j-favorite-item-delete', function () {
							var counter = $(this).data('counter'),
								products_id = $(this).data('products_id');
							wishlist.remove(products_id, function () {
							});

							$('.wishlist .carousel .viewport>.product[data-counter="' + counter + '"]').remove();
							$('#dots-block .finalCarouselControlDot[data-counter="' + counter + '"]').remove();
							$(this).closest('.product_wishlist').remove();
							$('.j-wishlist-add[data-products_id="' + products_id + '"]').removeClass('active').data('wishlist', 0);
						});
					}

					function initShowSlideEvent() {
						$('#wishlist_productslist .product_wishlist .j-see-product').click(function () {
							$('#favorites-carousel .product.alt').removeClass('active');
							$('#dots-block .finalCarouselControlDot').removeClass('active');

							var counter = $(this).data('counter'),
									pageActive = '#favorites-carousel .product.alt[data-counter="' + counter + '"]',
									dotActive = '#dots-block .finalCarouselControlDot[data-counter="' + counter + '"]';

							$(pageActive, $(this).closest('.j-contents')).addClass('active');
							$(dotActive, $(this).closest('.j-contents')).addClass('active');

							var list = $(this).closest('.products_list');

							list.addClass('hidden');
							list.next().removeClass('hidden');
							return false;
						});
					}
				}
				function renderSliders(data) {
					var favorites_carusel = $.render.favorites_carousel.render(data);

					$('#favorites-carousel').html(favorites_carusel);

					initLeftPicturesBlock();
					initBasketEvents();
					initBottomDots();


					function initLeftPicturesBlock() {
						$('#favorites-carousel .product.alt .thumbs a').click(function () {
							if ($(this).hasClass('active')) {
								return false;
							}
							$('a', $(this).parent()).removeClass('active');
							$(this).addClass('active');

							var bigPhotos = $('.viewport', $(this).parent().parent());
							$('a', $(bigPhotos)).removeClass('active');
							$('a[data-counter="' + $(this).data('counter') + '"]', $(bigPhotos)).addClass('active');
						});
					}

					function initBasketEvents(){
						$('.product.alt .general form ul.sizes li').click(function(){
							$('li',$(this).parent()).removeClass('active');
							$(this).addClass('active');
						});
					}

					function initBottomDots(){
						var dotsBlock = $.render.favorites_carousel_dots.render(data);

						$('#dots-block').html(dotsBlock);

						$('.finalCarouselControlDot:not(already-init)').click(function(){
							$('#dots-block .finalCarouselControlDot').removeClass('active');
							$(this).addClass('already-init').addClass('active');

							var counter = $(this).data('counter');

							$('#favorites-carousel .product.alt').removeClass('active');
							$('#favorites-carousel .product.alt[data-counter="' + counter + '"]').addClass('active');
						});

						$('.finalCarouselControlArrowLeft').click(function(){
							var prewDotPosition = $('#dots-block .finalCarouselControlDot.active').index();

							if(prewDotPosition<1){
								prewDotPosition = $('#dots-block .finalCarouselControlDot').length;
							}
							$('#dots-block .finalCarouselControlDot:nth-child('+prewDotPosition+')').trigger('click');
						});
						$('.finalCarouselControlArrowRight').click(function(){
							var nextDotPosition = +($('#dots-block .finalCarouselControlDot.active').index()) +2;

							if(nextDotPosition>$('#dots-block .finalCarouselControlDot').length){
								nextDotPosition = 1;
							}
							$('#dots-block .finalCarouselControlDot:nth-child('+nextDotPosition+')').trigger('click');
						});
					}

				}
			}
		}
	}

	/**
	 * ���������� �������
	 */
	function initBasket(){
		initDeleteCart();
		initChangeQuantity();

		function initDeleteCart() {
			$('#basket-popup').on('click', '.j-cart-delete', function (e) {
				e.preventDefault();
				shoppingCart.deleteProduct({
					products_id: $(this).data('composite-id')
				});
				$(this).closest('.product_carted').remove();
			});
		}

		function initChangeQuantity(){
			//��� �� �� ����������� ��� callback
			shoppingCart.addPopupEvents();
		}
	}

	/**
	 * ���� ��� �������� ������
	 * @returns {boolean}
	 */
	function initProductCart(){
		if(!$('#add_product-form')) {
			return false;
		}
		initGoUpButton();
		initCounterBlock($('#add_product-form .quantity'));
		initChangeQuantity();
		initFadeInFadeOutToSubscribe();
		initShowReviewsButton();

		$('.j-show-favorites').click(function(){
			$('.j-open-favorites').trigger('click');
			return false;
		});

		function initGoUpButton(){
			$('.js-go-up').click(function(){
				$("html, body").animate({ scrollTop: 0 }, "slow");
				return false;
			});
		}

		function initChangeQuantity() {
			$('#add_product-form').change(function () {
				var quantity = +$('input:checked', this).data('quantity'),
					warning = $('.product_rigth .limited_amount'),
					userWant = $('.product .quantity [name="quantity"]');

				$('#stock_balances').text(quantity);
				$('.js-product-quantity-plus').attr('title', '������ �� ������ �������� ' + quantity + ' ��.');

				if (quantity < 4) {
					$(warning).addClass('active');
				} else {
					$(warning).removeClass('active');
				}

				if (+$(userWant).val() > +quantity) {
					$(userWant).val(quantity);
					$('.js-product-quantity-plus').addClass('disable');
				}

				$(userWant).attr('max', quantity);
				$('.j-add-product').addClass('active');
				$('.j-go-shopping-cart').removeClass('active');
			});
		}

		function initFadeInFadeOutToSubscribe(){
			$('#one-product-mobile-navigation .j-icon-share-light').click(function(){
				$('#one-product-mobile-navigation .j-share-more').toggleClass('none')
			});
		}

		function initShowReviewsButton(){
			$('[href="#product-reviews"]').click(function(){
				var reviewsBlockTopPosition = $('#product-reviews').offset().top;

				$('html, body').animate({ scrollTop: reviewsBlockTopPosition},600);
				return false;
			});
		}
	}

	/**
	 * �� ��� ��������� � ������� ��������
	 */
	function initCabinet()
	{
		var cabinetBlock = $('.cabinet-section');
		if (!cabinetBlock.length){
			return false;
		}
		$('.panel').click(function(){
			$(this).toggleClass('open');
		});
		$('.cabinet-menu-list').scrollLeft($('li.active').position().left-50)
	}

	/**
	 * ����������� �� ��������
	 */
	function initSubscribe(){
		var popupBlock = $('#subscribe_ten_percent');

		if(!popupBlock.length){
			return true;
		}
		popup_handler('#subscribe_ten_percent');

		$('#subscribe-ten-percent-form').submit(function(){
			var parentBlock = $(this).closest('.modal-body'),
					email = parentBlock.find('[type="email"]'),
					outputBlock = parentBlock.find('output'),
					submit = parentBlock.find('[type="submit"]');

			submit.attr('disabled','disabled');
			$.get('/sent_coupon_500_5day.php',{email:email.val(),ajax:1}).done(function(data) {
				if(typeof data.error_code !== "undefined"){
					parentBlock.find('.subscribe').addClass('hide');
					parentBlock.find('.not-success').removeClass('hide');
					return true;
				}
				submit.removeAttr('disabled');
				parentBlock.find('.subscribe').addClass('hide');
				parentBlock.find('.success').removeClass('hide');
			}).fail(function() {
				submit.removeAttr('disabled');
				outputBlock.text(outputBlock.attr('data-fail'));
				setTimeout(function(){
					outputBlock.text('');
				},4000);
			});

			return false;
		});
	}

	function initMainPage(){
		if(!$('main.index_default').length){
			return false;
		}
		initTopSlider();
		initInTheSpotlightSlider();

		function initTopSlider(){
			var hasTopSliderContent = $('#main-page-top-slider').length > 0;

			if(!hasTopSliderContent){
				return false;
			}

			$('#main-page-top-slider').slick({
				arrows: false,
				dots:true,
				autoplay: true,
				autoplaySpeed: 4000,
			});
		}
		function initInTheSpotlightSlider(){
			$('.js-in-the-spotlight-slider').slick({
				slidesToShow: 5,
				arrows:false,
				dots:true,
				responsive: [{
					breakpoint: 1140,
					settings: {
						slidesToShow: 1,
						slidesToScroll: 1,
					},
				}],
			});
		}
	}

	$('.toggle').on('click',function(){
		$(this).toggleClass('active');

		if( $(this).hasClass('active') ){
			var h = 0;
			$(this).next().children().each(function(){
				h += $(this).outerHeight(true);
			})
			$(this).next().css({"--mh" : h + 'px'})

		} else {
			$(this).next().css({"--mh" : ''})
		}
	})

	$('#omni-pseudo-btn').on('click', function(){
		$(this).addClass('loading');
		loadOmni(this);
	});
});
/**
 * ������������� ��������� ��� �������� ��������� ���-��
 * @param parent
 */
function initCounterBlock(parent){
	if($(parent).hasClass('already-init')){
		return false;
	}
	$(parent).addClass('already-init');

	$('.js-product-quantity-minus', $(parent)).click(function(){
		var quantity = $('input[name="quantity"]', $(parent) ),
			count = +$(quantity).val();

		if(!quantity || +$(quantity).val() < 2 ){
			return false;
		}

		$(quantity).val(+$(quantity).val()-1);
		--count;
		if(count === 1){
			$(this).addClass('disable');
		}else{
			$(this).removeClass('disable');
		}

		$('.js-product-quantity-plus', $(parent)).removeClass('disable');
		$(quantity).trigger('change');
		return false;
	});
	$('.js-product-quantity-plus', $(parent)).click(function(){
		var quantity = $('input[name="quantity"]', $(parent) ),
			count = +$(quantity).val(),
			max = +$(quantity).attr('max'),
			isOver = max && max < count + 1 ;

		if(isOver){
			return false;
		}

		$(quantity).val(+$(quantity).val()+1);
		++count;

		if(count === max){
			$(this).addClass('disable');
		}else{
			$(this).removeClass('disable');
		}

		$('.js-product-quantity-minus', $(parent)).removeClass('disable');
		$(quantity).trigger('change');
		return false;
	});
	$('[name="quantity"]', $(parent)).change(function(){
		var max = +$(this).attr('max');
		if(!max){
			return true;
		}
		if(max< +$(this).val()){
			$(this).val(max);
		}
	});
}

function initLoginForm() {
	$('.j-to-email').on('click', function(){
		let $form = $(this).closest('form');
		$('.j-code-holder, .j-login-btn, .j-reget-pass, .j-phone-holder', $form).addClass('d-none');
		$('.j-get-pass, .j-mail-holder', $form).removeClass('d-none');
		$('input[name="phone"]',$form).val('');
		$('.j-to-phone',$form).removeClass('active');
		$(this).addClass('active');
	});
	$('.j-to-phone').on('click', function(){
		let $form = $(this).closest('form');
		$('.j-code-holder, .j-login-btn, .j-reget-pass, .j-mail-holder', $form).addClass('d-none');
		$('.j-get-pass, .j-phone-holder', $form).removeClass('d-none');
		$('input[name="email_address"]',$form).val('');
		$('.j-to-email',$form).removeClass('active');
		$(this).addClass('active');
	});
	$('.j-get-pass').on('click', function(){
		if ( $(this).hasClass('disabled') ) return;
		$(this).addClass('disabled');
		let $form = $(this).closest('form');
		let $button = $(this);
		let phone = $form.find('input[name=phone]').val();
		let email_address = $form.find('input[name=email_address]').val();
		$('.j-form-error', $form).text('');
		$.ajax({
			url: "/ajax_customer.php?action=checkAndSendPassCode&ajax=1",
			type: 'POST',
			dataType: 'json',
			data: {
				phone: phone.length > 0 ? '7' + phone.replace(/[^0-9]/g, '').substr(1) : '',
				email_address: email_address,
			},
			error: function(msg) {
				$button.removeClass('disabled');
				$('.j-form-error', $form).text(msg.err_mes);
			},
			success: function(msg) {
				$button.removeClass('disabled');
				if ( msg.result == 1 ) {
					$button.addClass('d-none');
					$('.j-code-holder, .j-login-btn, .j-reget-pass, .js-reget-pass-call', $form).removeClass('d-none');
				}
				$('.j-form-error', $form).text(msg.err_mes);
			}
		});
	})
	$('.j-reget-pass').on('click', function(){
		let $form = $(this).closest('form');
		let $button = $(this);
		let phone = $form.find('input[name=phone]').val();
		let email_address = $form.find('input[name=email_address]').val();
		$('.j-form-error', $form).text('');
		$.ajax({
			url: "/ajax_customer.php?action=resendNamePassCode&ajax=1",
			type: 'POST',
			dataType: 'json',
			data: {
				phone: phone.length > 0 ? '7' + phone.replace(/[^0-9]/g, '').substr(1) : '',
				email_address: email_address,
			},
			error: function(msg) {
				$('.j-form-error', $form).text(msg.err_mes);
			},
			success: function(msg) {
				$('.j-form-error', $form).text(msg.err_mes);
			}
		});
	});
	$('.js-reget-pass-call').on('click', function(){
		let $form = $(this).closest('form');
		let $button = $(this);
		let phone = $form.find('input[name=phone]').val();
		let email_address = $form.find('input[name=email_address]').val();
		$('.j-form-error', $form).html('');
		$.ajax({
			url: "/ajax_customer.php?action=recallWithPassCode&ajax=1",
			type: 'POST',
			dataType: 'json',
			data: {
				phone: phone.length > 0 ? '7' + phone.replace(/[^0-9]/g, '').substr(1) : '',
				login: email_address,
			},
			error: function(msg) {
				$('.j-form-error', $form).html(msg.err_mes);
			},
			success: function(msg) {
				$button.addClass('d-none');
				$('.j-form-error', $form).html(msg.err_mes);
			}
		});
	});
	$('.j-login-btn').on('click', function(){
		if ( $(this).hasClass('disabled') ) return;
		$(this).addClass('disabled');
		let $form = $(this).closest('form');
		let $button = $(this);
		let phone = $form.find('input[name=phone]').val();
		let email_address = $form.find('input[name=email_address]').val();
		let passCode = $form.find('input[name=passCode]').val();
		$('.j-form-error', $form).text('');
		customer.loginByPassCode({
			customerInfo: {
				phone: phone,
				passCode: passCode,
				email_address: email_address
			},
			error: function(msg) {
				$button.removeClass('disabled');
				console.log(msg);
				$('.j-form-error', $form).text(msg.err_mes);
			},
			success: function(msg) {
				if ( $('#door').length ) {
					$('#door').modal('hide');
				}
				if ( $('#login-form').length ) {
					window.location.href = '/account.php';
				}
				if( PROSKTR.content == 'shopping_cart' && msg.count_contents_before != msg.count_contents_after ) {
					window.location.reload();
				}
			}
		});
	});
	var regForm = $('#popup-registration-form');
	regForm.on('submit', function(e) {
		e.preventDefault();
	});
	regForm.validate({
		onChange: true,
		valid: function(event, options) {
			var customer_info = {};
			var translate = {
				'firstname': 'customers_firstname',
				'email_address': 'customers_email_address',
				'telephone': 'customers_telephone',
				'password': 'customers_password',
			};
			$.each($(this).serializeArray(), function(k, v) {
				customer_info[(translate[v.name] ? translate[v.name] : v.name)] = v.value;
			});
			customer.create({
				customer_info: customer_info,
				success: function() {
					$('#reg-success').modal('show');
				},
				error: function(msg) {
					$('.error', '#popup-registration-form').find('.validation-description').html(msg.err_mes);
				}
			});
		},
		invalid: function(a, b, c, d) {}
	});
	$('#login-form').loginForm({
		onLoginFunc: function(msg) {
			window.location.href = msg.redirect;
		},
		inputRow: '.form-group',
	});
}

function initProductDelivery(){
	$('.collapsible button').on('click', function(){
		let button = $(this);
		if(button.attr('aria-expanded')=='true'){
			button.attr('aria-expanded','false').siblings('.collapsible__inner').slideUp( "slow" );
		}
		else{
			$('.collapsible button[aria-expanded="true"]').each(function(){
				$(this).attr('aria-expanded','false').siblings('.collapsible__inner').slideUp( "slow" );
			});
			button.attr('aria-expanded','true').siblings('.collapsible__inner').slideDown( "slow" );
		}
	});
	var wrapper = $('.product');
  if (wrapper.length > 0) {
		var deliveryDescrForm = $('#products_info_delivery');
		var deliveryWrapper = $('.fast-delivery', wrapper);
		$('#products_info_delivery').ajaxForm({
		  success: function(data) {
		      $('.product-delivery-list', deliveryWrapper).show();
		      $('.product-delivery-choose-size', deliveryWrapper).hide().removeClass('loading');
		      $('#product-delivery-res', deliveryWrapper).html(data.content[0]);
		      $('#product-delivery-pre-title-res', deliveryWrapper).html(data.content[1]);
		  }
		});
		if (deliveryDescrForm.data('onload') == 1) {
		  deliveryDescrForm.submit();
		}
		$('.product-delivery-city-name', deliveryWrapper).on('citychange', function(e) {
		  deliveryDescrForm.submit();
		});
	}
	$('#change-city-popup form').on('submit', function(e) {
    e.preventDefault();
    var city = $('.city-tab.active [name=city]', this).val()
    var zone_id = $('.city-tab.active [name=zone_id]', this).val()
    $('#change-city-popup .close').click();
    var data = {
        city: city,
        zone_id: zone_id,
        ajax: 3,
    };
    $.ajax({
        url: '/ajax.php?action=getDeliveryBeneft',
        data: data,
        type: 'POST',
        dataType: 'json',
        success: function(msg) {
            $('.js-set-city-val').val(msg.city);
            $('.js-set-zone_id-val').val(msg.zone_id);
            $('.js-set-city-text').text(msg.city);
            $('.js-city-changed').trigger('citychange');
        }
    });
  });
  $('#change-city-popup form').selectCity();
}